import React, {
  Fragment,
} from 'react'
import styled from 'styled-components'
import Divider from '../../../../../components/divider'
import formatCurrency from '../../../../../helpers/formatCurrency'

const CartPreviewWrapper = styled.div`
  .upsellplus-cart-offer {
    width: 100%;
    filter: drop-shadow(0 4px 4px #BFBFBF);
    .upsellplus-cart-top-bar {
      background: #E7E7E7;
      position: relative;
      border-radius: 8px 8px 0 0;
      .Polaris-Icon {
        position: absolute;
        left: 5px;
        top: 3px;
        svg {
          width: 20px;
        }
      }
      .site-preview {
        position: absolute;
        right: 5px;
        top: 3px;
        color: #0CA127;
      }
      p {
        padding: 20px 0;
        text-align: center;
        margin: 0;
      }
    }
    .upsellplus-cart-body {
        background: white;
        padding-bottom: 25px;
        .upsellplus-cart-product {
          display: flex;
          padding: 35px 15px;
          gap: 10px;
          .upsellplus-cart-image-container {
            box-sizing: border-box;
            display: inline-block;
            width: 64px;
            height: 64px;
            flex: 0 0 64px;
            border-radius: 5px;
            border: 1px solid #aaa;
            margin-right: 6px;
            background: white;
            align-self: center;
            .upsellplus-cart-image {
              object-fit: contain;
              object-position: center;
              height: 62px;
              width: 62px;
              border-radius: 5px;
            }
          }
          .upsellplus-cart-product-info {
            .upsellplus-cart-title {
              font-weight: bold;
            }
            .upsellplus-cart-product-original-price {
              font-weight: bold;
              color: #C4C4C4;
              text-decoration: line-through;
              margin-right: 5px;
            }
            .upsellplus-cart-product-discounted-price {
              font-weight: bold;
              color: #008060;
            }
            .upsellplus-cart-product-price {
              font-weight: bold;
              color: #008060;
            }
            .upsellplus-cart-description {
              margin-top: 10px;
            }
          }
      }
      .upsellplus-cart-action-container {
            padding: 0 15px;
            .upsellplus-cart-selection {
              width: 100%;
              text-align: center;
              height: 35px;
              padding: 0;
            }
            .upsellplus-cart-button {
              align-items: center;
              box-shadow: rgba(22,29,37,.05) 0 1px 0 0;
              box-sizing: border-box;
              cursor: pointer;
              font-size: 13.3333px;
              justify-content: center;
              line-height: 1;
              margin: 10px 0 0;
              padding: 5px 20px;
              position: relative;
              text-align: center;
              text-decoration: none;
              user-select: none; 
              border: none;
              width: 100%;
              height: 35px;
              flex-basis: 100%;
              .Polaris-Spinner {
                svg {
                  width: 20px;
                }
              }    
            }
            .upsellplus-cart-button-content {
              font-weight: var(--p-button-font-weight, 400);
              line-height: 16px;
              text-transform: initial;
              letter-spacing: initial;
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              min-width: 1px;
              min-height: 1px;  
            }
            .upsellplus-cart-button-text {
              font-size: 14px;
              font-weight: 500;
            }
            .upsellplus-cart-skip {
              text-align: center;
              color: #0CA127;
              margin: 10px 0;
              text-decoration: none;
            }
            .upsellplus-cart-checkout {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin: 70px 0 10px 0;
              .upsellplus-cart-skip-and-continue {
                a {
                  text-decoration: underline;
                  color: #000;
                }
              }
              div {
                flex-grow: 1;
                text-align: center;
              }
              .upsellplus-cart-checkout-button {
                background: #000;
                color: #fff;
                flex-grow: 1;
                padding: 5px 20px;
                border: none;
                border-radius: 3px;
                height: 35px;
                a {
                  text-decoration: none;
                  color: #fff;
                }
              }
            }
            .upsellplus-cart-powered-by {
              display: flex;
              justify-content: flex-end;
              margin-top: 10px;
              padding-bottom: 10px;
              div {
                width: 50%;
                text-align: center;
                img {
                  width: 15px;
                  margin-right: 5px;
                }
              }
            }
      }
      
    }
  }
  
`
const translations = {
  "en-US": {
    "skip-checkout": "Continue to checkout",
    "skip": "Skip and continue"
  },
  "en": {
    "skip-checkout": "Continue to checkout",
    "skip": "Skip and continue"
  },
  "it": {
    "skip-checkout": "Continua con il checkout",
    "skip": "Salta e continua"
  }
};
function CartPopupPreview(props) {

  const { introText, topBarBackgroundColor, popupBorderRadius, description, bodyBackgroundColor, buttonHeight, introTextColor, titleColor, descriptionColor, product, checkoutButtonText,checkoutButtonBackgroundColor,checkoutButtonTextColor,checkoutButtonBorderRadius,checkoutButtonHeight, variants, title, cta, ctaBackgroundColor, ctaButtonTextColor, buttonBorderRadius, price, discount, shopBrain, featuredImage, locale, currency, loading, addToCart, closePopup, variantID, setVariantID, formatMoney, error, type, shop, checkoutTextColor, checkoutText } = props



  const handleChange = (event) => {
    setVariantID(event.target.value);
  }

  let featuredImagePreview = featuredImage || ((product && product?.images && Array.isArray(product?.images) && product?.images.length > 0) ? product?.images[0].originalSrc : "")

  if(variants){
    const selectedVariant = variants.find(variant => variant.id === variantID)
    if(selectedVariant && selectedVariant?.image){
      featuredImagePreview = selectedVariant.image
    }
  }
  let selectedVariant = {price:"0.00"}
  if(variants){
    //TODO: change it to id
    selectedVariant = variants.find(variant => variant.id === variantID)
    if(!selectedVariant){
      selectedVariant = variants[0]
    }
  }
  return (
    <Fragment>
      <CartPreviewWrapper>
        <div className='upsellplus-cart-offer'>
          <div className='upsellplus-cart-top-bar' style={{background: topBarBackgroundColor, borderTopLeftRadius: popupBorderRadius, borderTopRightRadius: popupBorderRadius}}>
            <span className="Polaris-Icon Polaris-Icon--colorBase Polaris-Icon--applyColor" onClick={closePopup}><span className="Polaris-VisuallyHidden"></span><svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path d="M10 14a.997.997 0 0 1-.707-.293l-5-5a.999.999 0 1 1 1.414-1.414L10 11.586l4.293-4.293a.999.999 0 1 1 1.414 1.414l-5 5A.997.997 0 0 1 10 14z"></path></svg></span>
            <p style={{color:introTextColor}}>{ introText || 'People also bought' }</p>
            {"sitePreview" === type ?
              <span className='site-preview'>Preview</span>
              :
              false
            }
          </div>
          <div className='upsellplus-cart-body' style={{background: bodyBackgroundColor}}>
            <div className='upsellplus-cart-product'>
              <div className="upsellplus-cart-image-container">
                <img className="upsellplus-cart-image" src={(shopBrain ? 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/smart-rules.png?alt=media&token=dc855a7c-567e-40b2-941f-df99fa0986d7' : featuredImagePreview && featuredImagePreview ) || 'https://via.placeholder.com/200'} alt="" />
              </div>
              <div className='upsellplus-cart-product-info'>
                <p className="upsellplus-cart-title" style={{
                  color:titleColor
                }}>
                  {title || (product && product.title) || 'Offer product title'}
                </p>

                {discount && "" != discount.value ?
                  <div>
                    <span className="upsellplus-cart-product-original-price">{"function" === typeof formatMoney && selectedVariant ? formatMoney(parseFloat(selectedVariant.price)*100, window.Shopify.money_format) : formatCurrency(selectedVariant.price,locale,currency)}</span>
                    {"%" == discount.sym ?
                      <span className="upsellplus-cart-product-discounted-price">{"function" === typeof formatMoney && selectedVariant ? formatMoney((parseFloat(selectedVariant.price) * ((100 - parseFloat(discount.value)) / 100))*100, window.Shopify.money_format) :formatCurrency((selectedVariant.price * ((100 - parseFloat(discount.value)) / 100)).toFixed(2), locale, currency)}</span>
                      :
                      <span className="upsellplus-cart-product-discounted-price">{"function" === typeof formatMoney && selectedVariant ? formatMoney((parseFloat(selectedVariant.price)  - parseFloat(discount.value))*100, window.Shopify.money_format) :formatCurrency((selectedVariant.price  - parseFloat(discount.value)).toFixed(2), locale, currency)}</span>
                    }
                  </div>
                  :
                  <div>
                    <span className="upsellplus-cart-product-price">{formatCurrency(selectedVariant.price,locale,currency)}</span>
                  </div>
                }

                <p className="upsellplus-cart-description" style={{color:descriptionColor}}>{ ("sitePreview" === type || "preview" === type) ? (description ||  'Offer product description. This is where the description goes.'):description}</p>
              </div>
            </div>
            <div className="upsellplus-cart-action-container">
              {variants && variants.length > 1 ?
                <select value={variantID} onChange={handleChange} className="upsellplus-cart-selection">
                  {variants.map((variant,index) =>
                    <option value={variant.id} key={index}>{variant.title}</option>
                  )}
                </select>
                :
                false
              }
              <button className="upsellplus-cart-button" style={{
                backgroundColor: ctaBackgroundColor,
                borderRadius: buttonBorderRadius,
                color: ctaButtonTextColor,
                height: buttonHeight+"px"
              }} onClick={addToCart}>
                {loading ?
                  <span className="Polaris-Spinner Polaris-Spinner--sizeSmall"><svg style={{fill: ctaButtonTextColor}} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.229 1.173a9.25 9.25 0 1011.655 11.412 1.25 1.25 0 10-2.4-.698 6.75 6.75 0 11-8.506-8.329 1.25 1.25 0 10-.75-2.385z"></path></svg></span>
                  :
                  <span className="upsellplus-cart-button-content">
                            <span className="upsellplus-cart-button-text">{cta || 'Add to cart'}</span>
                          </span>
                }
              </button>
              { "skinnymedetox.myshopify.com" !== shop ?
              <a href={"preview" === type ? "#":"/checkout"} className='upsellplus-cart-checkout-link' style={{color: checkoutTextColor ? checkoutTextColor:"#0CA127"}}><p className='upsellplus-cart-skip' style={{color: checkoutTextColor ? checkoutTextColor : "#0CA127"}}>{checkoutText ? checkoutText : translations[locale]? translations[locale]['skip-checkout']:translations['en']['skip-checkout']}</p></a>
              :
              false
              }
              {
                error && <div className="notice notice--error default-background" data-banner="true" role="alert" aria-atomic="true" aria-live="polite" style={{color: '#c54440'}}>
                  <svg className="icon-svg icon-svg--size-24 notice__icon" aria-hidden="true" focusable="false" style={{height: '20px',width: '20px',marginRight: '5px'}}>
                    <use xlinkHref="#upsell-error">
                      <symbol id="upsell-error">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                          <path fill="#c54440" d="M12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zm0-2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0-16c.552 0 1 .448 1 1v5c0 .552-.448 1-1 1s-1-.448-1-1V7c0-.552.448-1 1-1zm-1.5 10.5c0-.828.666-1.5 1.5-1.5.828 0 1.5.666 1.5 1.5 0 .828-.666 1.5-1.5 1.5-.828 0-1.5-.666-1.5-1.5z"/>
                        </svg>
                      </symbol>
                    </use>
                  </svg>
                  {error}
                </div>
              }
            </div>
          </div>
        </div>
      </CartPreviewWrapper>
    </Fragment>

  )
}
export default CartPopupPreview;